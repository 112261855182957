.chayns__utils__container--centered {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;

  &-container {
    &--gap:not(&--vertical) {
      & > *:not(:last-child) {
        margin-right: 15px;
      }
    }

    &--gap.chayns__utils__container--centered--vertical {
      & > *:not(:last-child) {
        margin-top: 15px;
      }
    }

    &--vertical {
      flex-direction: column;
    }
  }
}
