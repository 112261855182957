.chayns__utils__container--centered {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
}

@keyframes "fade-in" {
    0% {
        transform: scale(0);
    }
    99% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
