.header_bar_wrapper .header_bar .tobit-software-logo-wrapper {
  height: 100%;
  width: 153px;
  min-width: 153px;
  display: flex;
  align-items: center;

  & > img {
    max-height: 50px;
  }
}
