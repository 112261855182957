@font-face {
    font-family: "Roboto Regular";
    src: url("../public/Roboto-Regular.ttf");
}

body {
    padding: 0 !important;
    font-family: 'Roboto Regular', 'Tahoma', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.no-scroll {
    overflow: hidden !important;
}

body {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 15px 10px 20px;
    width: 100%;
    max-width: 100vw;
    width: -webkit-fill-available;
    color: #222;
    color: var(--chayns-color--text);
}

:root {
    font-family: 'Roboto', sans-serif;
    line-height: 1.65;
    font-size: 15px;
    --font-size: 15px;
    --base-font-size: 15px;
    font-size: var(--base-font-size, 15px);
}

.checkWrapper footer {
    margin-top: 0;
}

.cc__list .list-item {
    border-bottom-color: rgba(0, 94, 185, 0.45) !important;
}

.cc__list .list-item {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(110, 110, 110, 0.45);
    border-bottom-color: rgba(var(--chayns-color-rgb--headline), 0.45);
}

.cc__list .list-item {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(110, 110, 110, 0.45);
    border-bottom-color: rgba(var(--chayns-color-rgb--headline), 0.45);
}

.cc__list .list-item--clickable > .list-item__header {
    cursor: pointer;
}

.cc__list .list-item--clickable > .list-item__header {
    cursor: pointer;
}

.cc__list .list-item__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 64px;
    padding: 12px 9px;
}

*:not(.button--show-focus),
*:before,
*:after {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.cc__list .list-item__subtitle {
    margin-top: 2px;
    font-size: 85%;
    opacity: 0.75;
}

.cc__list .list-item__spacer {
    flex-grow: 1;
}

.animation__accordion--open,
.accordion--open > .accordion__body {
    transition: max-height 0.5s cubic-bezier(0.5, 0, 1, 0) 0s;
    will-change: max-height;
}

.animation__accordion--close,
.accordion__body {
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1) -0.1s;
    will-change: max-height;
}

*:not(.button--show-focus),
*:before,
*:after {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
}

.cc__list .list-item__content {
    padding: 9px 18px 9px 35px;
}

.dateWrapper .errorWrapper .statusIcon {
    margin-right: 8px;
}
