.dialog-open {
  z-index: 99999 !important;
  opacity: 1 !important;
  backdrop-filter: blur(4px) !important;
}

.dialog-close {
  backdrop-filter: blur(0) !important;
  opacity: 0 !important;
  z-index: -1 !important;
}

.dialog {
  border-radius: 2px;
  padding: 15px 16px;
  margin-bottom: -30px;
  display: flex;
  flex: auto;
  flex-direction: column;
  width: 100vw;
  max-height: 280px;
  opacity: 1;
  background-color: #ffffff;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  box-shadow: 1px 2px 40px rgba(0, 0, 0, 0.3);
  z-index: 1;
  transform: none;
}

.dialog--desktop {
  max-width: 400px !important;
}

.dialog-move--in {
  transform: translate3d(0, -30px, 0)
}

.dialog-root {
  align-items: flex-end;
  box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.75);
  background: transparent;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100vh;
  overflow-y: overlay;
  transition: opacity 0.2s ease-in-out, -webkit-backdrop-filter .2s ease-in-out;
  transition: opacity 0.2s ease-in-out, backdrop-filter .2s ease-in-out;
  transition: opacity 0.2s ease-in-out, backdrop-filter .2s ease-in-out, -webkit-backdrop-filter .2s ease-in-out;
  opacity: 0;
  display: flex;
  justify-content: center;
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
  max-height: 100%;
}

.dialog-root--desktop {
  align-items: center !important;
}

@-webkit-keyframes fadeIn {
  from {
    transform: translateY(15px);
    opacity: 0
  }
  to {
    transform: translateY(0px);
    opacity: 1
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(15px);
    opacity: 0
  }
  to {
    transform: translateY(0px);
    opacity: 1
  }
}
