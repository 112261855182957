.app {
  * {
    box-sizing: border-box;

    &:before {
      box-sizing: border-box;
    }

    &:after {
      box-sizing: border-box;
    }
  }
}

.animation__wrapper {
  overflow: visible !important;
}

.header_bar_wrapper {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  --darkreader-inline-bgcolor: #1d1f20;
  --darkreader-inline-border-bottom: rgba(168, 156, 138, 0.15);
  transition: background-color .2s;
  height: 50px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  max-width: 100vw !important;
  z-index: 1;
  overflow: hidden;

  @supports (-webkit-touch-callout: none) {
    padding-right: 30px;
  }

  @supports not (-webkit-touch-callout: none) {
    padding-right: 10px;
  }
}

.header_bar_wrapper .header_bar {
  display: flex;
  justify-content: space-between;;
  align-items: center;
  height: 100%;
}

.header_bar_wrapper .header_bar .chayns-logo-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px
}


h2 {
  margin: 0 0 10px 0;
  font-weight: normal;
  font-family: inherit !important;
  word-spacing: 0;
  letter-spacing: 0;
  font-size: 1.4rem;
  color: #005eb8;
}

.cc__list {
  .list-item__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 64px;
    padding: 12px 9px;
  }

  .list-item--clickable {
    > .list-item__header {
      cursor: pointer;
    }
  }

  .list-item__subtitle {
    margin-top: 2px;
    font-size: 85%;
    opacity: 0.75;
  }
}

.center {
  max-width: 800px;
  pointer-events: all;
  margin: 25px auto 30px;
  padding: 15px 10px 20px;
}

.checkWrapper {
  text-align: center;
  margin-bottom: 60px;

  .status {
    margin-top: 20px;
    text-align: center;
    font-size: 1.7rem;
    line-height: 30px;
  }
}

a {
  &:not(.no-link-style) {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(34, 34, 34, 0.4);
  }

  color: currentColor;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
}

* {
  &:not(.button--show-focus) {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
  }

  &:before {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
  }

  &:after {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
  }
}

.dateWrapper {
  margin-top: 45px;
}

.more {
  margin-top: 30px;
  text-align: right;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.help {
  margin-top: 64px;

  h1 {
    margin: 1.2rem 0 5px 0;
    line-height: 1.35;
    font-weight: normal;
    font-family: "Roboto Regular", "Tahoma", sans-serif;
    word-spacing: 0;
    letter-spacing: 0;
    font-size: 1.7rem;
    color: #005eb8;
  }
}

.buttonWrapper {
  text-align: center;
  margin-top: 20px;

  button {
    background-color: #005eb8;
  }
}


.cc__list .list-item--clickable:hover, .cc__list .list-item--expanded {
  background-color: #f1f0f0;
}


.accordion:hover {
  background-color: #f8f7f7;

  &:not(.accordion--wrapped) {
    border-bottom-color: #005eb8;
  }
}

.input--border-design {
  max-height: 42px !important;
}
