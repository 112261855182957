.data-row {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  display: flex;
  justify-content: space-between;
  align-items: center !important;

  &--slim {
    @extend .data-row;
    padding-top: 0;
  }

  & .centered {
    display: flex;
    align-items: center
  }
}
