.content__card.content__card--warning {
    border-width: 1px;
    border-style: solid;
    border-color: #9f5f00;
    background-color: #fff3e0;
    color: #222;
    --chayns-color--text: #222;
    --chayns-color-rgb--text: 34, 34, 34;
}

.content__card.content__card--success {
    border-width: 1px;
    border-style: solid;
    border-color: #9f5f00;
    background-color: #fff3e0;
    color: #222;
    --chayns-color--text: #222;
    --chayns-color-rgb--text: 34, 34, 34;
}

.content__card.content__card--danger {
    border-width: 1px;
    border-style: solid;
    border-color: #9f5f00;
    background-color: #fff3e0;
    color: #222;
    --chayns-color--text: #222;
    --chayns-color-rgb--text: 34, 34, 34;
}

.content__card {
    padding: 8px 12px;
    background-color: #f1f0f0;
    background-color: var(--chayns-color--secondary-100);
}
